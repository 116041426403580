<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
            mb-3
          "
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 m-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'layout.customer' }" class=""
                    >Customers</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Edit Customer
                </li>
              </ol>
            </nav>
          </div>
          <div
            @click="handleBackBtn"
            class="
              btn btn-secondary btn-sm
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="ml-2">Back</span>
          </div>
        </div>
      </div>
      <div v-if="emailAlreadyExist" class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 text-center">
                <h5 class="text-danger">
                  {{
                    emailAlreadyExist
                      ? "User Already Exist with this email"
                      : "User Already Exist with this phone"
                  }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="row g-3 date-icon-set-modal">
                  <div class="col-md-6 mb-3">
                    <label
                      for="Text1"
                      class="form-label font-weight-bold text-muted"
                      >First Name *</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text1"
                      placeholder="Edit First Name"
                      v-model="firstName"
                      @change="handleFirstName"
                    />
                    <label
                      v-if="invalidFirstName"
                      for="Text1"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Invalid First Name</label
                    >
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="Text1" class="form-label text-muted"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text1"
                      placeholder="Edit Last Name"
                      v-model="lastName"
                      @change="handleLastName"
                    />
                    <label
                      v-if="invalidLastName"
                      for="Text1"
                      class="
                        ftext-uppercase
                        text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Invalid Last Name</label
                    >
                  </div>

                  <div class="col-md-6 mb-3">
                    <label
                      for="Text5"
                      class="form-label font-weight-bold text-muted"
                      >Phone</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text5"
                      placeholder="Edit Phone"
                      v-model="phone"
                      disabled
                    />
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="Text4" class="form-label text-muted"
                      >Email</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text4"
                      placeholder="Edit Email"
                      v-model="email"
                      :disabled="!hasDummyEmail ? true : false"
                      @change="handleEmail"
                    />
                    <label
                      v-if="invalidEmail"
                      for="Text1"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Invalid Email</label
                    >
                  </div>
                  <!-- v-if=!referrer -->
                  <div class="col-md-6 mb-3">
                     <label for="Text4" class="form-label text-muted"
                      >Referrer</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text5"
                      placeholder="Enter Referrer"
                      :disabled="hasReferrer === true ? false : true"
                      v-model="referrer"
                      @change="handleReferrer"
                    />
                    <label
                      v-if="invalidReferrer"
                      for="Text1"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Invalid Referrer</label
                    >
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="Text9" class="form-label text-muted"
                      >Notes</label
                    >
                    <textarea
                      class="form-control notesArea"
                      id="Text9"
                      rows="2"
                      placeholder="Edit Notes"
                      v-model="notes"
                      @keypress="handleNotesCheck"
                    >
                    </textarea>
                  </div>
                </form>

                <div class="d-flex flex-wrap justify-content-between mt-3">
                  <button
                    class="btn btn-success btn-sm"
                    @click="updateCustomer"
                    id="edit-customer"
                  >
                    Add Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_CUSTOMERS_DETAILS } from "../../../graphql/query";
import { UPDATE_USER } from "../../../graphql/mutation";

export default {
  name: "CustomerEdit",
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      notes: "",
      referrer: "",
      hasDummyEmail: "",
      hasReferrer:"",
      invalidFirstName: false,
      invalidLastName: false,
      invalidEmail: false,
      invalidReferrer: false,
      emailAlreadyExist: false,
    };
  },
  props: ["number"],
  beforeMount() {
    this.getUserDetails();
  },
  methods: {
    getUserDetails() {
      this.$apollo
        .query({
          query: GET_CUSTOMERS_DETAILS,
          variables: {
            id: this.number,
          },
        })
        .then((data) => {
          this.firstName = data.data.bwsDashboardUser.profile.firstName;

          this.lastName = data.data.bwsDashboardUser.profile.lastName;
          this.phone = data.data.bwsDashboardUser.profile.phone;
          this.email = data.data.bwsDashboardUser.profile.email;
          this.referrer = data.data.bwsDashboardUser.profile.referrer
          if(this.referrer !== null){
            this.hasReferrer = false
            this.referrer = data.data.bwsDashboardUser.profile.referrer.code
          }
          else{
            this.hasReferrer = true
            this.referrer = ''
          }
          
          this.hasDummyEmail = data.data.bwsDashboardUser.profile.hasDummyEmail;
        });
    },
    updateCustomer() {
      document.getElementById("edit-customer").disabled = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: this.number,
            firstName: this.firstName,
            lastName: this.lastName,
            notes: this.notes,
            email: this.email,
            referrer: this.referrer,
          },
        })
        // .then(() => {
        //   this.$router.go(-1);
        // });
        .then((data) => {
          if (data.data.userUpdate.errors.length == 0) {
            // this.$router.push({name: 'layout.customer'})
            // location.reload();
            this.$router.go(-1);
            document.getElementById("edit-customer").disabled = false;
          } else {
            document.getElementById("edit-customer").disabled = false;
            this.errors = data.data.userUpdate.errors;

            this.errors.map((e) => {
              if (
                e.message == "Kindly provide first name in English." ||
                this.firstName == ""
              ) {
                return (this.invalidFirstName = true);
              } else if (
                e.message == "Kindly provide family name in English."
              ) {
                return (this.invalidLastName = true);
              } else if (e.message == "User with this Email already exists.") {
                return (this.emailAlreadyExist = true);
              } else if (e.message == "Enter a valid email address.") {
                return (this.invalidEmail = true);
              } else if (e.message == "Incorrect referral code.") {
                return (this.invalidReferrer = true);
              }
            });
          }
        });
    },
    handleBackBtn() {
      this.$router.go(-1);
    },

    handleFirstName() {
      this.invalidFirstName = false;
      this.emailAlreadyExist = false;
      // this.phoneAlreadyExist = false
    },

    handleLastName() {
      this.invalidLastName = false;
      this.emailAlreadyExist = false;
      // this.phoneAlreadyExist = false
    },
     handleReferrer(e){
      this.referrer = e.target.value
      this.invalidReferrer = false
      this.emailAlreadyExist = false
    },
    handleEmail() {
      this.invalidEmail = false;
      this.emailAlreadyExist = false;
      // this.phoneAlreadyExist = false
    },
    handleNotesCheck(e) {
      var charCode = !e.charCode ? e.which : e.charCode;
      if (charCode === 45) e.preventDefault();
    },
  },
};
</script>
<style>
.notesArea {
  line-height: 25px !important;
}
</style>

